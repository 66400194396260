<template>
  <div>
    <b-row class="mb-2 justify-content-end" v-if="!loading">
      <b-col md="12" lg="2">
        <b-button
          block
          variant="primary"
          class="float-right"
          @click="exportExcell"
        >
          Download Excel
        </b-button>
      </b-col>
    </b-row>
    <b-card v-if="loading">
      <div class="d-flex align-items-center">
        <strong>Loading...</strong>
        <b-spinner class="ml-auto" />
      </div>
    </b-card>
    <b-card v-if="!loading">
      <b-row>
        <b-col>
          <div class="custom-search d-flex justify-content-start">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Filter</label>
                <b-form-select
                  id="filter"
                  :options="option"
                  v-model="filter"
                  @change="onFilterChange()"
                />
                <b-form-select
                  id="subFilter"
                  class="ml-1"
                  :options="optionSub"
                  v-model="subFilter"
                  v-show="showSubFilter"
                  @change="onSubFilterChange()"
                />
              </div>
            </b-form-group>
            <b-form-group>
              <div class="d-flex align-items-center ml-2">
                <label class="mr-1">Date</label>
                <flat-pickr
                  v-model="filterDate"
                  class="form-control"
                  :config="{
                    dateFormat: 'Y-m-d',
                    minDate: '2022-03-21',
                    mode: 'range',
                  }"
                  placeholder="Date"
                  @on-change="onDateChange()"
                />
              </div>
            </b-form-group>
          </div>
        </b-col>
        <b-col>
          <div class="custom-search d-flex justify-content-md-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="search"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-pagination
            :value="meta.current_page"
            :total-rows="meta.total"
            :per-page="meta.per_page"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-2"
            @input="
              (value) =>
                getDatas(
                  value,
                  this.subFilter ? this.subFilter : this.filter,
                  this.search,
                  this.filterDate
                )
            "
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: true,
          perPage: meta.per_page,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field === 'status'"
            class="d-block text-center"
          >
            <b-badge :variant="statusVariant(props.row.status)">
              {{ statusName(props.row.status) }}
            </b-badge>
          </span>
          <span
            v-else-if="props.column.field === 'image'"
            class="d-block text-center"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              class="btn-icon"
              v-b-modal.modal-center
              @click="showImage(props.row.image)"
            >
              <feather-icon icon="CameraIcon" />
            </b-button>
          </span>

          <span
            v-else-if="props.column.field === 'has_activation'"
            class="d-block text-center"
          >
            <span v-if="props.row.has_activation === 0" class="mt-50 d-block">
              -
            </span>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              class="btn-icon"
              v-b-modal.modal-activation
              @click="showActivation(props.row.id)"
              v-if="props.row.has_activation === 1"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'action'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="props.row.has_activation == 0"
                @click="addActivation(props.row.id)"
              >
                <feather-icon icon="CheckCircleIcon" class="mr-50" />
                <span>Aktifasi</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.status > 0"
                @click="showDetail(props.row.id)"
              >
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span>Detail</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing {{ meta.from }} to {{ meta.to }}
              </span>
              <span class="text-nowrap ml-50">
                of {{ meta.total }} entries
              </span>
            </div>
            <div>
              <b-pagination
                :value="meta.current_page"
                :total-rows="meta.total"
                :per-page="meta.per_page"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="
                  (value) =>
                    getDatas(
                      value,
                      this.subFilter ? this.subFilter : this.filter,
                      this.search,
                      this.filterDate
                    )
                "
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <b-modal id="modal-center" centered title="Bukti Foto" ok-only size="lg">
      <div class="text-center">
        <b-img :src="media" class="img-fluid" />
      </div>
    </b-modal>
    <b-modal id="modal-activation" centered title="Aktivasi" ok-only size="lg">
      <b-row>
        <b-col cols="6">
          <b-form-group label="Tanggal Aktivasi" label-for="activation">
            <b-form-input
              id="activation"
              v-model="dataActivation.date"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Tipe Aktivasi" label-for="type_activation">
            <b-form-input
              id="type_activation"
              v-model="dataActivation.type"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Bukti Aktivasi" label-for="image_activation">
        <div class="text-center">
          <b-img :src="dataActivation.media" class="img-fluid" />
        </div>
      </b-form-group>
    </b-modal>
    <div @click="fabAction">
      <vue-fab mainBtnColor="#3867d6" size="big" :scrollAutoHide="false">
      </vue-fab>
    </div>
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BModal,
  VBModal,
  BImg,
  BSpinner,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import downloadexcel from "vue-json-excel";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    VueGoodTable,
    ToastificationContent,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    VBModal,
    BImg,
    downloadexcel,
    flatPickr,
    BSpinner,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    const { baseUrl } = $themeConfig.app;
    return {
      baseUrl,
    };
  },
  data() {
    return {
      loading: false,
      pageLength: 10,
      dir: false,
      columns: [
        { field: "user_name", label: "Agen", sortable: true, filterable: true },
        { field: "name", label: "Nasabah", sortable: true, filterable: true },
        { field: "phone", label: "No Hp", sortable: false, filterable: true },
        {
          field: "account_no",
          label: "No Rekening",
          sortable: false,
          filterable: true,
        },
        {
          field: "proven_at",
          label: "Tanggal Terdaftar",
          sortable: true,
          width: "200px",
          filterable: false,
        },
        { field: "note", label: "Note", sortable: false, filterable: false },
        {
          field: "image",
          label: "Bukti",
          sortable: false,
          width: "85px",
          filterable: false,
        },
        {
          field: "has_activation",
          label: "Aktifasi",
          sortable: false,
          width: "100px",
          filterable: false,
        },
        {
          field: "status",
          label: "Status",
          sortable: true,
          width: "150px",
          filterable: false,
        },
        {
          field: "action",
          label: "Action",
          sortable: false,
          filterable: false,
          width: "100px",
          thClass: "text-center px-0",
          tdClass: "text-center",
        },
      ],
      rows: [],
      search: null,
      awaitingSearch: false,
      media: null,
      option: [],
      optionSub: [],
      filter: "ALL",
      subFilter: null,
      showSubFilter: false,
      meta: {
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0,
        per_page: 10,
        total: 0,
      },
      filterDate: null,
      dataActivation: {
        date: null,
        image: null,
        type: null,
        media: null,
      },
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        0: "light-secondary",
        1: "light-success",
        2: "light-primary",
        3: "light-warning",
      };

      return (status) => statusColor[status];
    },
    statusName() {
      const statusName = {
        0: "Pending",
        1: "Valid",
        2: "Uncompleted",
        3: "Need Follow Up",
      };

      return (status) => statusName[status];
    },
  },
  watch: {
    search: function (val) {
      if (!this.awaitingSearch) {
        this.filterDate = null;
        setTimeout(() => {
          if (this.showSubFilter) {
            this.getDatas(1, this.subFilter, this.search, this.filterDate);
          } else {
            this.getDatas(1, this.filter, this.search, this.filterDate);
          }
          this.filter = "ALL";
          this.subFilter = null;
          this.showSubFilter = false;
          this.filterDate = null;
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
  },
  created() {
    this.getDatas(1, "", this.search, this.filterDate);
    this.getFilterList();
  },
  methods: {
    getDatas($page, $filter, $search, $date) {
      this.loading = true;
      this.rows = [];

      if ($date != undefined) {
        var $rDateArr = $date.split(" to ");
      }

      this.$http
        .get(
          "/report?page=" +
            $page +
            ($filter ? "&filter=" + $filter : "") +
            ($search ? "&search=" + $search : "") +
            ($date
              ? "&start_at=" + $rDateArr[0] + "&end_at=" + $rDateArr[1]
              : "")
        )
        .then((res) => {
          this.loading = false;
          this.rows = res.data.data;
          var meta = res.data.meta;
          if (meta.from != null) {
            this.meta.from = meta.from;
          }
          if (meta.to != null) {
            this.meta.to = meta.to;
          }
          this.meta.current_page = meta.current_page;
          this.meta.last_page = meta.last_page;
          this.meta.per_page = meta.per_page;
          this.meta.total = meta.total;
        })
        .catch((errors) => {
          this.loading = false;
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    getFilterList() {
      this.$http
        .get("/user/downline")
        .then((res) => {
          let $data = res.data.data;

          this.option.push({
            value: "ALL",
            text: "Semua",
          });

          this.option.push({
            value: "SELF",
            text: "Saya",
          });

          for (let i = 0; i < $data.length; i++) {
            this.option.push({
              value: $data[i].id,
              text: $data[i].name,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSubFilterList($id) {
      this.optionSub = [];
      this.$http
        .get("/user/downline?id=" + $id)
        .then((res) => {
          let $data = res.data.data;

          this.optionSub.push({
            value: $id,
            text: "Semua",
          });

          this.optionSub.push({
            value: $id + "&only=true",
            text: "Only",
          });

          for (let i = 0; i < $data.length; i++) {
            this.optionSub.push({
              value: $data[i].id + "&only=true",
              text: $data[i].name,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fabAction: function () {
      this.$router.push("/pemrek-verify/add");
    },
    showImage($filename) {
      this.media = this.baseUrl + "image/report/" + $filename;
    },
    onFilterChange() {
      this.search = null;
      this.filterDate = null;
      this.getDatas(1, this.filter, this.search, this.filterDate);

      if (this.filter.toString() == "ALL" || this.filter.toString() == "SELF") {
        this.showSubFilter = false;
      } else {
        this.showSubFilter = true;
        this.getSubFilterList(this.filter);
      }
    },
    onSubFilterChange() {
      this.search = null;
      this.filterDate = null;
      this.getDatas(1, this.subFilter, this.search, this.filterDate);
    },
    async fetchData() {
      const response = this.getDatas(
        1,
        this.filter,
        this.search,
        this.filterDate
      );
      return response.data.data;
    },
    startDownload() {
      alert("show loading");
    },
    finishDownload() {
      alert("hide loading");
    },
    exportExcell() {
      var $filter = this.filter;
      var $search = this.search;
      var $date = this.filterDate;

      if ($date != undefined) {
        var $rDateArr = $date.split(" to ");
      }

      axios({
        url:
          $themeConfig.app.apiUrl +
          "report/export/pemrek?e=true" +
          ($filter ? "&filter=" + $filter : "") +
          ($search ? "&search=" + $search : "") +
          ($date
            ? "&start_at=" + $rDateArr[0] + "&end_at=" + $rDateArr[1]
            : ""),
        // timeout: 1000,
        responseType: "blob",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " +
            localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName),
        },
      }).then((res) => {
        const current = new Date();
        const date = `${current.getDate()}/${
          current.getMonth() + 1
        }/${current.getFullYear()}`;
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", date + "_report_pemrek.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    showDetail(id) {
      this.$store.state.app.pemrekId = id;
      localStorage.setItem("pemrekId", id);
      this.$router.push("/pemrek-verify/detail");
    },
    onDateChange() {
      if (this.filterDate != undefined) {
        if (this.showSubFilter) {
          this.getDatas(1, this.subFilter, this.search, this.filterDate);
        } else {
          this.getDatas(1, this.filter, this.search, this.filterDate);
        }
      }
    },
    showActivation($id) {
      this.$http
        .get("/report/activation/" + $id)
        .then((res) => {
          let $data = res.data.data;
          this.dataActivation = $data;

          if ($data.type === 1) {
            this.dataActivation.type = "Transaksi";
          } else if ($data.type === 2) {
            this.dataActivation.type = "Saldo Rata-rata";
          }

          this.dataActivation.media =
            this.baseUrl + "image/report/activation/" + $data.image;

          console.log(this.dataActivation.media);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addActivation(id) {
      this.$store.state.app.pemrekId = id;
      localStorage.setItem("pemrekId", id);
      this.$router.push("/pemrek-activation/add");
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
